<template>
    <div id="app">
        <NavBar :hasBackButton="casePageOpen || quizPageOpen" />
        <div class="spinner" v-if="!appLoaded" ref="spinner">...</div>
        <ContentSlider :state="!casePageOpen" :slides="slides" />
        <CasePage :content="casePageContent" :state="casePageOpen" />
        <QuizPage :content="casesOnly" :state="quizPageOpen" />
        <PageFooter
            :casePrev="prevCase"
            :caseNext="nextCase"
            :showPrevNext="casePageOpen"
            :showCloseBtn="quizPageOpen"
        />
        <section
            class="login d-flex flex-column justify-content-center"
            v-if="!loggedIn"
        >
            <b-container class="h-100">
                <b-row class="h-100 d-flex justify-content-center">
                    <b-col cols="12" md="6" lg="4" class="text-right">
                        <b-input
                            type="password"
                            placeholder="Введите пароль"
                            v-model="passwd"
                            @keypress.enter="checkPass"
                        ></b-input>
                        <b-btn
                            class="mt-3"
                            size="sm"
                            variant="light"
                            @click="checkPass"
                            >Войти</b-btn
                        >
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </div>
</template>

<script>
import NavBar from "./components/NavBar"
import ContentSlider from "./components/ContentSlider.vue"
import CasePage from "./components/CasePage.vue"
import QuizPage from "./components/QuizPage.vue"
import PageFooter from "./components/PageFooter.vue"
import SLIDES from "./fakeData"
import { QUIZ } from "./fakeData"

export default {
    name: "App",
    components: { NavBar, PageFooter, ContentSlider, CasePage, QuizPage },
    data: () => ({
        loggedIn: false,
        passwd: "",
        appLoaded: false,
        scrollEvts: ["mousewheel", "keyup", "touchmove", "touchend"],
        touchStart: 0,
        slides: SLIDES,
        quiz: QUIZ,
        casePageOpen: false,
        casePageContent: false,
        quizPageOpen: false,
        quizPageContent: false,
        menuOpen: false,
        prevCase: null,
        nextCase: null,
    }),
    computed: {
        casesOnly() {
            return this.slides.slice(2 - this.slides.length, this.slides.length)
        },
    },
    created() {
        this.loggedIn = window.localStorage.getItem("loggedIn")

        this.casePageContent = this.slides[2]

        setTimeout(() => this.$refs.spinner.classList.add("hidden"), 1000)
        setTimeout(() => {
            this.appLoaded = true
            this.$eventBus.$on("restoreEvents", this.attachScrollEvents)
        }, 2000)

        document.addEventListener("keyup", (e) => {
            if (e.code === "Escape") {
                this.casePageOpen = false
                this.quizPageOpen = false
            }
        })

        this.$eventBus.$on("showCase", (caseId) => {
            this.casePageContent = this.slides.find(
                (slide) => slide.id == caseId
            )
            this.casePageOpen = true
        })
        this.$eventBus.$on("hideCase", () => {
            this.casePageOpen = false
        })

        this.$eventBus.$on("showQuiz", () => {
            this.casePageOpen = false
            this.quizPageOpen = true
        })
        this.$eventBus.$on("hideQuiz", () => {
            this.quizPageOpen = false
        })
    },
    mounted() {
        this.attachScrollEvents()
    },
    methods: {
        attachScrollEvents() {
            this.scrollEvts.forEach((evtType) => {
                document
                    .querySelector(".slide_content_wrapper")
                    .addEventListener(
                        evtType,
                        (evt) => this.handleScroll(evt),
                        { passive: false, once: true }
                    )
            })
            console.log("Events attached")
        },
        handleScroll(evt) {
            let direction = "down"

            if (evt.type == "mousewheel") {
                direction = evt.deltaY > 0 ? "down" : "up"
                console.log("Events detached")
                evt.preventDefault()
                this.$eventBus.$emit("documentScroll", direction)
            }

            if (evt.type == "touchmove") {
                this.touchStart = evt.touches[0].clientY
                evt.preventDefault()
            }
            if (evt.type == "touchend") {
                if (this.touchStart > 0) {
                    let touchEnd = evt.changedTouches[0].clientY
                    direction = this.touchStart > touchEnd + 5 ? "down" : "up"
                    this.touchStart = 0
                    this.$eventBus.$emit("documentScroll", direction)
                } else {
                    this.$eventBus.$emit("restoreEvents")
                }
            }

            return false
        },
        checkPass() {
            if (this.passwd == "newtest") {
                this.loggedIn = true
                window.localStorage.setItem("loggedIn", true)
            }
        },
    },
    watch: {
        casePageContent(to) {
            this.prevCase = this.casesOnly[parseInt(to.id) - 3]
                ? this.casesOnly[parseInt(to.id) - 3]
                : this.casesOnly[this.casesOnly.length - 1]

            this.nextCase = this.casesOnly[parseInt(to.id) - 1]
                ? this.casesOnly[parseInt(to.id) - 1]
                : this.casesOnly[1]
        },
    },
}
</script>

<style lang="scss">
body,
#app {
    height: 100vh;
    overflow: hidden;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        pointer-events: none;
        background: url("./assets/img/noise.gif");
        opacity: 0.03;
    }
}
#app ::selection {
    color: #fff;
    background: red;
}
.spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    text-transform: uppercase;
    font-family: sans-serif;
    font-weight: bold;
    animation: 0.3s linear 0s infinite alternate spinner;
    pointer-events: none;

    &.hidden {
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.5s ease;
    }

    @keyframes spinner {
        from {
            text-indent: -10px;
        }
        to {
            text-indent: 8px;
        }
    }
}

.login {
    position: fixed;
    min-height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 10000;
    background: $black;
}
</style>
