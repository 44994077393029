<template>
    <section id="case-inner-wrapper" :class="{ open: state }">
        <div class="case-background-side side-left"></div>
        <div class="case-background-side side-right"></div>
        <section
            class="case-content"
            :style="getImageUrl('poll.jpg', 'left')"
            ref="case_content"
        >
            <div class="case-overlay"></div>
            <b-container class="h-100">
                <b-row class="h-100 align-content-center">
                    <b-col cols="12" md="6" lg="6">
                        <section class="subheader my-5">Голосование</section>
                        <header class="header-1 mb-5">
                            <span b>Лучший кейс</span>
                        </header>
                        <section class="subheader-1 my-5">
                            Корпоративные войны позади. Всего 10 эпизодов, но
                            каждый, по своему уникальный, важный для российского
                            права. Что вам запомнилось больше всего? Какое
                            сражение было решающим?
                        </section>
                    </b-col>
                    <b-col cols="1" class="d-none d-lg-block"></b-col>
                    <b-col cols="12" md="6" lg="5">
                        <section class="subheader my-5" v-if="results">
                            <ul class="quiz-variants">
                                <li
                                    v-for="(caseItem, idx) in quizVariants"
                                    :key="idx"
                                >
                                    <button
                                        class="variant-title-wrapper text-left"
                                        @click="selectedCase = caseItem.id"
                                        v-if="!hasVoted"
                                    >
                                        <span
                                            class="checker"
                                            :class="{
                                                selected:
                                                    caseItem.id == selectedCase,
                                            }"
                                        ></span>
                                        <span
                                            class="quiz-variant-title"
                                            v-text="stripHTML(caseItem.title)"
                                        ></span>
                                    </button>

                                    <div
                                        v-else
                                        class="variant-title-wrapper voted"
                                    >
                                        <div
                                            class="quiz-variant-title"
                                            v-text="stripHTML(caseItem.title)"
                                        ></div>
                                        <span
                                            class="percentage"
                                            :style="{
                                                width:
                                                    countPercentage(
                                                        caseItem.id
                                                    ) + '%',
                                            }"
                                        >
                                            {{ countPercentage(caseItem.id) }}%
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </section>
                        <button
                            v-if="selectedCase && !hasVoted"
                            @click="submitVote"
                            class="action-btn"
                        >
                            Голосовать
                        </button>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </section>
</template>

<script>
import axios from "axios"
import CasePage from "./CasePage.vue"

axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded"
axios.defaults.headers.post["Cache-Control"] = "no-cache"
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*"

export default {
    name: "QuizPageComponent",
    extends: CasePage,
    data: () => ({
        showResults: false,
        quizVariants: null,
        selectedCase: null,
        results: null,
        hasVoted: false,
    }),
    created() {
        this.hasVoted = localStorage.getItem("quiz") ? true : false
        this.quizVariants = this.content
    },
    mounted() {
        this.getResults()
    },
    methods: {
        stripHTML(htmlMarkup) {
            var div = document.createElement("div")
            div.innerHTML = htmlMarkup
            var text = div.textContent || div.innerText || ""
            return text
        },
        getResults() {
            axios({
                method: "get",
                url: "https://service.pravo.ru/corporate2021/quiz/",
            })
                .then((response) => (this.results = response.data))
                .catch((err) => {
                    console.log(err)
                })
        },
        submitVote() {
            let presentsInResults = this.results.find(
                (entry) => entry.id == this.selectedCase
            )
            if (!presentsInResults) {
                this.results.push({ id: this.selectedCase, votes: 1 })
            } else {
                presentsInResults.votes += 1
            }

            let formData = new FormData()
            formData.set("results", JSON.stringify(this.results))
            axios({
                method: "post",
                url: "https://service.pravo.ru/corporate2021/quiz/",
                data: formData,
            })
                .then(() => {
                    localStorage.setItem("quiz", this.selectedCase)
                    this.getResults()
                    this.hasVoted = true
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        countPercentage(caseId) {
            const totalScore = this.results
                .map((entry) => entry.votes)
                .reduce((prev, curr) => {
                    return prev + curr
                })

            let presentsInResults = this.results.find(
                (entry) => entry.id == caseId
            )
            if (presentsInResults)
                return Math.round((100 * presentsInResults.votes) / totalScore)
            else return 0
        },
    },
}
</script>

<style lang="scss">
.quiz-variants {
    li:not(:last-child) {
        margin-bottom: $space * 1.5;
    }

    .variant-title-wrapper {
        background: transparent;
        color: #fff;
        font-family: Helios, sans-serif;
        font-size: rem-calc(18);
        font-style: italic;
        text-transform: uppercase;
        border: none;
        display: flex;
        flex-flow: row;
        align-items: center;

        .quiz-variant-title {
            position: relative;
            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 50%;
                border-top: 4px solid $red;
                height: 0;
                width: 0;
                transform: translateX(-50%);
                transition: width 0.2s ease;
            }
        }

        &.voted {
            flex-wrap: wrap;
            flex-flow: column;
            padding-bottom: 20px;
            position: relative;
            .quiz-variant-title {
                display: block;
                width: 100%;
                &:after {
                    content: none;
                }
            }
            .percentage {
                left: 0;
                position: absolute;
                bottom: 10px;
                border: none;
                height: 10px;
                width: 0;
                background: $red;
                transform: none;
                transition: width 2s ease;
                text-align: right;
            }
        }

        &:not(.voted):hover {
            .quiz-variant-title:after {
                width: 100%;
                transition: width 0.2s ease;
            }
        }

        .checker {
            display: inline-block;
            margin-right: $space * 2;
            height: 28px;
            width: 28px;
            border: 2px solid #fff;
            position: relative;

            &.selected:after {
                content: "\0445";
                position: absolute;
                top: -5px;
                left: 2px;
                transform: scaleX(1.5);
                height: 100%;
                width: 100%;
                font-size: 2rem;
                font-style: normal;
                color: $red;
                font-family: "28days", sans-serif;
            }
        }
    }
}
</style>
