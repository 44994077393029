<template>
    <section id="content_slider">
        <MainMenu :cases="slides" />

        <div
            class="slide_content_wrapper"
            :class="{ 'no-display': !state }"
            ref="container"
        >
            <ContentSlide
                v-for="(slide, idx) in slides"
                :key="idx"
                :slide="slide"
                :slideIdx="idx"
                :currentSlide="currentIdx"
                :ref="`slide_${idx}`"
            />
        </div>

        <div class="background" ref="background">
            <img
                v-for="(slide, idx) in slides"
                :key="idx"
                :src="require(`@/assets/img/${slide.img}`)"
                :ref="`slide_bg_${idx}`"
                class="bg_img"
            />
        </div>
    </section>
</template>

<script>
import { TimelineLite, gsap } from "gsap"
import ContentSlide from "./ContentSlide"
import MainMenu from "./MainMenu.vue"

export default {
    name: "ContentSlider",
    components: { ContentSlide, MainMenu },
    props: { slides: { default: {} }, state: { default: true } },
    data: () => ({
        currentIdx: 0,
        speed: 1.5,
    }),
    computed: {
        timeline: () => new TimelineLite({ paused: true }),
    },
    created() {
        this.$eventBus.$on("documentScroll", (direction) => {
            if (
                !(
                    direction == "down" &&
                    this.currentIdx == this.slides.length - 1
                ) &&
                !(direction == "up" && this.currentIdx == 0)
            ) {
                direction == "down" ? this.next() : this.prev()
            } else {
                this.$eventBus.$emit("restoreEvents")
            }
        })
        this.$eventBus.$on("slideTo", (slideIdx) => this.goTo(slideIdx))
    },
    mounted() {
        gsap.to(this.$refs.container, { x: 0 })
        this.$eventBus.$emit("contentSliderInited", this.currentIdx)
    },
    methods: {
        prev() {
            this.currentIdx -= 1
            console.log(this.currentIdx)
            this.$eventBus.$emit("contentSliderChange", {
                from: this.currentIdx + 1,
                to: this.currentIdx,
            })
            gsap.to(this.$refs.container, {
                x: "-=100%",
                duration: this.speed,
                onComplete: () => this.$eventBus.$emit("restoreEvents"),
            })
            gsap.to(this.$refs.background, {
                y: "+=100%",
                duration: this.speed,
            })
        },
        next() {
            this.currentIdx += 1
            this.$eventBus.$emit("contentSliderChange", {
                from: this.currentIdx - 1,
                to: this.currentIdx,
            })
            gsap.to(this.$refs.container, {
                x: "+=100%",
                duration: this.speed,
                onComplete: () => this.$eventBus.$emit("restoreEvents"),
            })
            gsap.to(this.$refs.background, {
                y: "-=100%",
                duration: this.speed,
            })
        },
        goTo(slideIdx) {
            let prevIdx = this.currentIdx
            this.currentIdx = slideIdx
            console.log(prevIdx, this.currentIdx)
            this.$eventBus.$emit("contentSliderChange", {
                from: null,
                to: this.currentIdx,
                slideTo: true,
            })
            gsap.to(this.$refs.container, {
                x: `${slideIdx * 100}%`,
                duration: this.speed,
            })
            gsap.to(this.$refs.background, {
                y: `${prevIdx > slideIdx ? "+" : "-"}=${
                    Math.abs(slideIdx - prevIdx) * 100
                }%`,
                duration: this.speed,
            })
        },
    },
}
</script>

<style lang="scss">
#content_slider {
    height: 100vh;
    width: 100vw;
    position: relative;
    z-index: 1;

    .slide_content_wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        will-change: trnasform;
        opacity: 1;
        transition: opacity 0.5s ease;
        &.no-display {
            opacity: 0;
            transition: opacity 0.5s ease;
        }
    }
    .background {
        height: 100vh;
        z-index: 0;
        .bg_img {
            position: relative;
            width: 100%;
            height: 100vh;
            object-fit: cover;
        }
    }
}
</style>
