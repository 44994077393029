var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:{ open: _vm.state },attrs:{"id":"case-inner-wrapper"}},[_c('div',{staticClass:"case-background-side side-left"}),_c('div',{staticClass:"case-background-side side-right"}),_c('section',{ref:"case_content",staticClass:"case-content",style:(_vm.getImageUrl('poll.jpg', 'left'))},[_c('div',{staticClass:"case-overlay"}),_c('b-container',{staticClass:"h-100"},[_c('b-row',{staticClass:"h-100 align-content-center"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('section',{staticClass:"subheader my-5"},[_vm._v("Голосование")]),_c('header',{staticClass:"header-1 mb-5"},[_c('span',{attrs:{"b":""}},[_vm._v("Лучший кейс")])]),_c('section',{staticClass:"subheader-1 my-5"},[_vm._v(" Корпоративные войны позади. Всего 10 эпизодов, но каждый, по своему уникальный, важный для российского права. Что вам запомнилось больше всего? Какое сражение было решающим? ")])]),_c('b-col',{staticClass:"d-none d-lg-block",attrs:{"cols":"1"}}),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"5"}},[(_vm.results)?_c('section',{staticClass:"subheader my-5"},[_c('ul',{staticClass:"quiz-variants"},_vm._l((_vm.quizVariants),function(caseItem,idx){return _c('li',{key:idx},[(!_vm.hasVoted)?_c('button',{staticClass:"variant-title-wrapper text-left",on:{"click":function($event){_vm.selectedCase = caseItem.id}}},[_c('span',{staticClass:"checker",class:{
                                            selected:
                                                caseItem.id == _vm.selectedCase,
                                        }}),_c('span',{staticClass:"quiz-variant-title",domProps:{"textContent":_vm._s(_vm.stripHTML(caseItem.title))}})]):_c('div',{staticClass:"variant-title-wrapper voted"},[_c('div',{staticClass:"quiz-variant-title",domProps:{"textContent":_vm._s(_vm.stripHTML(caseItem.title))}}),_c('span',{staticClass:"percentage",style:({
                                            width:
                                                _vm.countPercentage(
                                                    caseItem.id
                                                ) + '%',
                                        })},[_vm._v(" "+_vm._s(_vm.countPercentage(caseItem.id))+"% ")])])])}),0)]):_vm._e(),(_vm.selectedCase && !_vm.hasVoted)?_c('button',{staticClass:"action-btn",on:{"click":_vm.submitVote}},[_vm._v(" Голосовать ")]):_vm._e()])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }