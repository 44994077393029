<template>
    <section id="case-inner-wrapper" :class="{ open: state }">
        <!-- <main-menu :cases="SLIDES" /> -->
        <div class="case-background-side side-left"></div>
        <div class="case-background-side side-right"></div>
        <section class="case-content" :style="getImageUrl()" ref="case_content">
            <div class="case-overlay"></div>
            <b-container>
                <b-row>
                    <b-col cols="12">
                        <section
                            class="subheader my-5"
                            v-html="content.sub_text"
                        ></section
                    ></b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <header
                            class="header-1 mb-5"
                            v-html="content.title"
                        ></header>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" md="3" lg="2">
                        <header class="header-3 mt-1 mb-4">
                            Ключевые дела
                        </header>
                        <ul class="docs-list">
                            <li
                                v-for="(doc, idx) in content.docs"
                                :key="idx"
                                class="mb-4"
                            >
                                <header
                                    class="header-2 mb-1"
                                    v-if="doc.title.length"
                                >
                                    {{ doc.title }}:
                                </header>
                                <a :href="doc.url" class="small">{{
                                    doc.urlText
                                }}</a>
                            </li>
                        </ul>
                    </b-col>
                    <b-col cols="12" md="9" lg="9">
                        <article
                            class="case-text"
                            v-html="content.article.text"
                        ></article>
                        <article class="case-text">
                            <header class="header-3 mb-2">
                                Комментарий юриста
                            </header>
                            <section v-html="content.article.comment"></section>
                        </article>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </section>
</template>

<script>
// import SLIDES from "../fakeData"
// import MainMenu from "./MainMenu.vue"

export default {
    name: "CasePageComponent",
    // components: { MainMenu },
    data: () => ({
        // SLIDES,
    }),
    props: {
        state: { required: true },
        content: { required: true },
    },
    beforeUpdate() {
        this.$refs.case_content.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        })
    },
    methods: {
        getImageUrl(
            imageName = parseInt(this.content.id) - 1 + ".jpg",
            side = "right"
        ) {
            try {
                return {
                    background: `url(${require("../assets/img/cases_bg/" +
                        imageName)}) no-repeat center ${side}/33%`,
                }
            } catch (e) {
                return ""
            }
        },
    },
}
</script>

<style lang="scss">
#case-inner-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    padding-top: 70px;
    padding-bottom: 130px;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    filter: blur(20px);
    transition: opacity 0.5s ease, filter 0.3s linear;

    .case-background-side {
        position: absolute;
        top: 0;
        width: 50%;
        height: 100%;
        background: #000;
        &.side-left {
            left: -50%;
            transition: left 0.5s ease;
        }
        &.side-right {
            right: -50%;
            transition: right 0.5s ease;
        }
    }

    &.open {
        opacity: 1;
        filter: blur(0px);
        pointer-events: all;

        .side-left {
            left: 0;
            transition: left 0.5s ease;
        }
        .side-right {
            right: 0;
            transition: right 0.5s ease;
        }
    }

    .case-content {
        height: 100%;
        overflow-y: auto;
        position: relative;
        z-index: 2;
        color: #fff;
        transition: background 0.2s ease;

        &:before {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, 0.55);
            pointer-events: none;
        }

        .header-1 span[b] {
            display: block;
            text-align: center;
            color: $red;
            font-family: "28days", sans-serif;
            font-weight: 100;
        }

        .case-text {
            font-family: Roboto, sans-serif;
        }

        a {
            color: $red;
        }

        .docs-list {
            a {
                text-decoration: underline;
                line-height: 1.15;
                display: block;
                font-size: rem-calc(14);
            }
        }

        p {
            margin-bottom: $space * 2.5;
        }
    }
}
</style>
