<template>
    <footer id="page-footer">
        <b-container>
            <b-row class="text-white">
                <b-col
                    class="pl-0 order-2 order-md-1 mr-auto align-self-center"
                    cols="auto"
                >
                    <b-btn
                        variant="link"
                        class="quiz-link px-0"
                        @click="showQuizPage"
                    >
                        Лучший кейс
                    </b-btn>
                </b-col>
                <b-col
                    cols="12"
                    class="justify-content-center col-md order-1 order-md-2"
                >
                    <transition name="fade">
                        <b-row
                            class="
                                d-flex
                                align-items-center
                                h-100
                                cases-pagination
                            "
                            v-if="showPrevNext || showCloseBtn"
                        >
                            <b-col
                                cols="auto"
                                md="6"
                                class="
                                    text-right
                                    d-flex
                                    h-100
                                    justify-content-end
                                    align-items-center
                                "
                                :style="{ opacity: showPrevNext ? 1 : 0 }"
                            >
                                <b-btn
                                    variant="link"
                                    class="d-none d-md-flex case-prev"
                                    v-html="casePrev.title"
                                    @click="setCase(casePrev.id)"
                                ></b-btn>
                                <b-btn
                                    variant="link"
                                    class="d-flex d-md-none case-prev"
                                    v-html="`&nbsp;`"
                                    @click="setCase(casePrev.id)"
                                ></b-btn>
                            </b-col>
                            <b-col class="d-md-none text-center">
                                <b-btn
                                    variant="link"
                                    @click="
                                        $eventBus.$emit('hideCase')
                                        $eventBus.$emit('hideQuiz')
                                    "
                                    >на главную</b-btn
                                >
                            </b-col>
                            <b-col
                                cols="auto"
                                md="6"
                                class="text-left d-flex"
                                :style="{ opacity: showPrevNext ? 1 : 0 }"
                            >
                                <b-btn
                                    variant="link"
                                    class="d-none d-md-flex case-next"
                                    v-html="caseNext.title"
                                    @click="setCase(caseNext.id)"
                                ></b-btn>
                                <b-btn
                                    variant="link"
                                    class="d-flex d-md-none case-next"
                                    v-html="`&nbsp;`"
                                    @click="setCase(caseNext.id)"
                                ></b-btn>
                            </b-col>
                        </b-row>
                    </transition>
                </b-col>
                <b-col
                    class="
                        d-flex
                        justify-content-end
                        align-items-center
                        pr-0
                        order-3
                        ml-auto
                    "
                    cols="auto"
                >
                    <div class="sound-msg hidden" ref="sound_msg">
                        <span class="close-btn">×</span>
                        Погрузитесь в атмосферу "Корпоративных войн" под
                        уникальный саундтрек
                    </div>
                    <button
                        type="button"
                        class="toggle-sound"
                        :class="{ 'sound-off': !soundOn }"
                        @click="toggleSound"
                    ></button>
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>

<script>
export default {
    name: "PageFooterComponent",
    props: {
        casePrev: { default: null },
        caseNext: { default: null },
        showPrevNext: { default: false },
        showCloseBtn: { default: false },
    },
    data: () => ({
        soundOn: false,
    }),
    computed: {
        audio() {
            return new Audio("/corporate.mp3")
        },
        audioMsgHidden() {
            return localStorage.getItem("audioMsgHidden") ? true : false
        },
    },
    mounted() {
        this.audio.volume = 0.5
        this.audio.addEventListener(
            "ended",
            function () {
                this.currentTime = 0
                this.play()
            },
            false
        )

        const audioMsg = this.$refs.sound_msg
        if (!this.audioMsgHidden) {
            setTimeout(() => {
                audioMsg
                    .querySelector(".close-btn")
                    .addEventListener("click", function () {
                        audioMsg.classList.add("hidden")
                        setTimeout(() => audioMsg.remove(), 500)
                        localStorage.setItem("audioMsgHidden", true)
                    })

                audioMsg.classList.remove("hidden")
            }, 2500)
        } else {
            audioMsg.remove()
        }
    },
    methods: {
        toggleSound() {
            this.soundOn = !this.soundOn
            if (this.soundOn) {
                this.audio.play()
            } else {
                this.audio.pause()
            }
        },
        setCase(caseId) {
            this.$eventBus.$emit("showCase", caseId)
            // this.$eventBus.$emit("contentSliderChange", { to: caseId })
        },
        showQuizPage() {
            this.$eventBus.$emit("showQuiz")
        },
    },
}
</script>

<style lang="scss">
#page-footer {
    width: 100%;
    padding: 0.5rem 1rem;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 3;
    background: rgba(0, 0, 0, 0.8);
    transition: all 2s ease;

    .quiz-link {
        font-family: "28days", sans-serif;
        color: $red;
        text-transform: uppercase;
        font-weight: 100;
        font-size: rem-calc(24);
        @include media-breakpoint-down(md) {
            font-size: rem-calc(20);
        }
        @include media-breakpoint-down(xs) {
            font-size: rem-calc(16);
        }
    }

    .toggle-sound {
        width: 36px;
        height: 36px;
        cursor: pointer;
        border: none;
        position: relative;
        background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='93.038px' height='93.038px' viewBox='0 0 93.038 93.038' style='enable-background:new 0 0 93.038 93.038;' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M46.547,75.521c0,1.639-0.947,3.128-2.429,3.823c-0.573,0.271-1.187,0.402-1.797,0.402c-0.966,0-1.923-0.332-2.696-0.973 l-23.098-19.14H4.225C1.892,59.635,0,57.742,0,55.409V38.576c0-2.334,1.892-4.226,4.225-4.226h12.303l23.098-19.14 c1.262-1.046,3.012-1.269,4.493-0.569c1.481,0.695,2.429,2.185,2.429,3.823L46.547,75.521L46.547,75.521z M62.784,68.919 c-0.103,0.007-0.202,0.011-0.304,0.011c-1.116,0-2.192-0.441-2.987-1.237l-0.565-0.567c-1.482-1.479-1.656-3.822-0.408-5.504 c3.164-4.266,4.834-9.323,4.834-14.628c0-5.706-1.896-11.058-5.484-15.478c-1.366-1.68-1.24-4.12,0.291-5.65l0.564-0.565 c0.844-0.844,1.975-1.304,3.199-1.231c1.192,0.06,2.305,0.621,3.061,1.545c4.977,6.09,7.606,13.484,7.606,21.38 c0,7.354-2.325,14.354-6.725,20.24C65.131,68.216,64.007,68.832,62.784,68.919z M80.252,81.976 c-0.764,0.903-1.869,1.445-3.052,1.495c-0.058,0.002-0.117,0.004-0.177,0.004c-1.119,0-2.193-0.442-2.988-1.237l-0.555-0.555 c-1.551-1.55-1.656-4.029-0.246-5.707c6.814-8.104,10.568-18.396,10.568-28.982c0-11.011-4.019-21.611-11.314-29.847 c-1.479-1.672-1.404-4.203,0.17-5.783l0.554-0.555c0.822-0.826,1.89-1.281,3.115-1.242c1.163,0.033,2.263,0.547,3.036,1.417 c8.818,9.928,13.675,22.718,13.675,36.01C93.04,59.783,88.499,72.207,80.252,81.976z' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A")
            no-repeat center center/contain;

        &:active,
        &:focus {
            outline: none;
        }
        &:after {
            content: "";
            position: absolute;
            top: -2px;
            left: 14px;
            width: 3px;
            height: 120%;
            border-left: 3px solid $red;
            transform: rotate(-45deg);
            opacity: 0;
            transition: opacity 0.2s ease;
        }
        &.sound-off:after {
            opacity: 1;
            transition: opacity 0.2s ease;
        }
    }

    .cases-pagination {
        button {
            color: #fff;
            font-family: "Helios", sans-serif;
            font-weight: 600;
            font-style: italic;
            &:hover {
                text-decoration: none;
            }
            &:active,
            &:focus {
                box-shadow: none;
                text-decoration: none;
            }

            &.case-prev {
                padding-left: 40px;
                background: $iconShevronLeft no-repeat left center/28px;
            }

            &.case-next {
                padding-right: 40px;
                background: $iconShevronRight no-repeat right center/28px;
            }
        }
        span[b] {
            display: inline-block;
            font-style: normal;
            font-family: "28days", sans-serif;
            font-weight: 100;
            color: $red;
            margin-left: 6px !important;
        }

        .col-auto:first-child {
            border-right: 1px solid red;

            @include media-breakpoint-down(xs) {
                border: none;
            }
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s, transform 0.5s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
        transform: translateY(-100%);
    }

    .sound-msg {
        position: absolute;
        top: 0;
        right: 30px;
        transform: translateY(calc(-110% - 15px));
        background: $red;
        color: #fff;
        width: 250px;
        max-height: 150px;
        padding: $space * 1.2 $space * 1.2 $space $space;
        border-radius: 2px;
        font-family: "Helios", sans-serif;
        font-variant: petite-caps;
        text-transform: lowercase;
        line-height: 1.1;
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.8);
        opacity: 1;
        transition: opacity 0.3s ease, transform 0.3s ease;

        @include media-breakpoint-down(xs) {
            right: -15px;
        }

        &.hidden {
            opacity: 0;
            transform: translateY(-150%);
            transition: opacity 0.3s ease, transform 0.3s ease;
        }

        .close-btn {
            position: absolute;
            top: $space/6;
            right: $space/2;
            font-family: Roboto, sans-serif;
            cursor: pointer;
            width: 18px;
            height: 18px;
            text-align: right;
        }

        &:after {
            content: "";
            position: absolute;
            right: 15px;
            bottom: -14px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 15px 20px 0 20px;
            border-color: $red transparent transparent transparent;
        }
    }
}
</style>
