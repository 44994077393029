<template>
    <div class="main_menu_wrapper">
        <nav id="main_menu">
            <div class="seekbar">
                <div class="progress" :style="{ width: progress }"></div>
            </div>
        </nav>

        <button
            class="hamburger hamburger--spin menu_btn"
            ref="menu_btn"
            type="button"
            @click="toggleMenu"
        >
            <span class="hamburger-box">
                <span class="hamburger-inner"></span>
            </span>
        </button>

        <section class="cases_list" ref="cases_list">
            <ul>
                <li :class="{ active: 1 == currentSlideIdx }">
                    <button type="button" @click="slideTo(1)">
                        Вступление
                    </button>
                </li>
                <li
                    v-for="(pt, idx) in menuCasesList"
                    :key="idx"
                    :class="{ active: idx + 2 == currentSlideIdx }"
                >
                    <button type="button" @click="slideTo(idx + 2)">
                        {{ pt.title }}
                    </button>
                </li>
            </ul>
        </section>
    </div>
</template>

<script>
export default {
    name: "MainMenu",
    props: { cases: { default: {} } },
    data: () => ({
        progress: 0,
        currentSlideIdx: 0,
        menuCasesList: [],
    }),
    mounted() {
        this.$eventBus.$on("contentSliderChange", (data) =>
            this.progressBarWidth(data.to)
        )
        this.prepareCasesList()
    },
    methods: {
        prepareCasesList() {
            this.cases.forEach((item, idx) => {
                if (idx > 1) {
                    let tmp = document.createElement("div")
                    tmp.innerHTML = item.title
                    this.menuCasesList.push({
                        title: tmp.textContent || tmp.innerText || "",
                    })
                }
            })
        },
        progressBarWidth(slideIdx) {
            this.currentSlideIdx = slideIdx
            this.progress =
                (parseInt(slideIdx > 0 ? slideIdx + 1 : slideIdx) /
                    this.cases.length) *
                    100 +
                "%"
        },
        toggleMenu() {
            this.$refs.menu_btn.classList.toggle("is-active")
            this.$refs.cases_list.classList.toggle("open")
        },
        slideTo(slideIdx) {
            this.$eventBus.$emit("slideTo", slideIdx)
            this.toggleMenu()
        },
    },
}
</script>

<style lang="scss">
#main_menu {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    z-index: 1;
    .seekbar {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        height: 2px;
        background: $red;
    }
    .progress {
        height: 7px;
        background: $red;
        width: 0;
        transform: translateY(-3px);
        transition: width 1.5s linear;
        border-radius: 0;
    }
}

.menu_btn {
    position: fixed;
    top: 50%;
    right: $space;
    transform: translateY(-45%);
    z-index: 3;
    padding-right: 0 !important;

    @include media-breakpoint-down(md) {
        right: $space;
    }

    @include media-breakpoint-down(xs) {
        top: 15%;
        right: $space;
    }

    &:focus {
        outline: none;
    }
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
        background-color: #fff !important;
        border-radius: 0;
    }
    &.is-active {
        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            background-color: #000 !important;
        }
    }
}

.cases_list {
    position: fixed;
    top: 0;
    right: -50%;
    width: 50%;
    height: 100vh;
    background: #fff;
    z-index: 2;
    padding: 60px 70px;
    box-shadow: 0 0 10px #000;
    transition: right 0.4s ease;
    overflow-y: auto;
    display: flex;
    align-items: center;

    &.open {
        right: 0;
        transition: right 0.4s ease;
    }

    @include media-breakpoint-down(md) {
        padding: 60px 35px;
    }

    @include media-breakpoint-down(xs) {
        width: 100%;
        right: -100%;
        padding: 60px $space $space;
    }

    li {
        button {
            display: inline-block;
            position: relative;
            border: none;
            background: none;
            font-family: Helios, sans-serif;
            font-style: italic;
            font-size: 2.2rem;
            text-transform: uppercase;
            transition: color 0.5s ease;
            text-align: left;
            margin-bottom: $space/1.5;
            line-height: 1.1;

            &:active,
            &:focus {
                outline: none;
            }
            &:after {
                content: "";
                position: absolute;
                left: 50%;
                bottom: 0;
                height: 4px;
                width: 0;
                background: $red;
                transition: width 1.5s ease, left 1.5s ease;
            }

            @include media-breakpoint-down(md) {
                font-size: rem-calc(26);
            }

            @include media-breakpoint-down(xs) {
                font-size: 1rem;
            }
        }
        &.active {
            button {
                position: relative;
                color: $red;
                transition: color 0.5s ease;
                &:after {
                    width: 97%;
                    left: 5px;
                    transition: width 1.5s ease, left 1.5s ease;
                }
            }
        }
    }
}
</style>
